<template>
  <div id="overlay" ref="overlay" v-on:click.stop tabindex="1" @keydown="keydown">
    <div id="overlay-content">
      <div id="top-bar">
        <div id="left-bar" class="span10">
          <div id="top-controls">
            <div class="function-group">
              <p>Select</p>
              <div class="btn-group">
                <button class="btn btn-primary" @click="selectAll" title="Select all balloons">All</button>
                <button class="btn btn-primary" @click="invertSelect" title="Invert balloon selection">Invert</button>
                <button class="btn btn-primary" @click="reSelect" :disabled="cannotReSelect" title="Re-select the last balloons selected if you've nothing currently selected">Redo</button>
              </div>
            </div>
            <div class="seperator"></div>
            <div class="function-group">
              <p>Edit</p>
              <div class="btn-group">
                <button class="btn btn-success btn-mini" @click="undoEdit" :disabled="!canUndo" title="Undo last move operation">
                  <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" transform="translate(0, 2)"><path fill="#FFFFFF" d="M280-200v-80h284q63 0 109.5-40T720-420q0-60-46.5-100T564-560H312l104 104-56 56-200-200 200-200 56 56-104 104h252q97 0 166.5 63T800-420q0 94-69.5 157T564-200H280Z"/></svg>
                </button>
                <button class="btn btn-success btn-mini" @click="redoEdit" :disabled="!canRedo" title="Redo last move operation">
                  <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" transform="translate(0, 2)"><path fill="#FFFFFF" d="M396-200q-97 0-166.5-63T160-420q0-94 69.5-157T396-640h252L544-744l56-56 200 200-200 200-56-56 104-104H396q-63 0-109.5 40T240-420q0 60 46.5 100T396-280h284v80H396Z"/></svg>
                </button>
              </div>
            </div>
            <div class="function-group">
              <p>Move</p>
              <div class="btn-group">
                <button class="btn btn-success btn-mini" @click="arrangeBalloonsMoveToEdge('left')" title="Move to the left edge">
                  <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" transform="rotate(90, -2, 2)"><path fill="#FFFFFF" d="M160-120v-80h640v80H160Zm320-160L280-480l56-56 104 104v-408h80v408l104-104 56 56-200 200Z"/></svg>
                </button>
                <button class="btn btn-success btn-mini" @click="arrangeBalloonsMoveToEdge('right')" title="Move to the right edge">
                  <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" transform="rotate(270, 2, 2)"><path fill="#FFFFFF" d="M160-120v-80h640v80H160Zm320-160L280-480l56-56 104 104v-408h80v408l104-104 56 56-200 200Z"/></svg>
                </button>
                <button class="btn btn-success btn-mini" @click="arrangeBalloonsMoveToEdge('top')" title="Move to the top edge">
                  <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" transform="rotate(180, 0, 2)"><path fill="#FFFFFF" d="M160-120v-80h640v80H160Zm320-160L280-480l56-56 104 104v-408h80v408l104-104 56 56-200 200Z"/></svg>
                </button>
                <button class="btn btn-success btn-mini" @click="arrangeBalloonsMoveToEdge('bottom')" title="Move to the bottom edge">
                  <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" transform="translate(0, 2)"><path fill="#FFFFFF" d="M160-120v-80h640v80H160Zm320-160L280-480l56-56 104 104v-408h80v408l104-104 56 56-200 200Z"/></svg>
                </button>
              </div>
            </div>
            <div class="function-group">
              <p>Align</p>
              <div class="btn-group">
                <button class="btn btn-success btn-mini" @click="arrangeBalloonsAlign('left')" title="Align left horizontally">
                  <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" transform="translate(0, 2)"><path fill="#FFFFFF" d="M80-80v-800h80v800H80Zm160-200v-120h400v120H240Zm0-280v-120h640v120H240Z"/></svg>
                </button>
                <button class="btn btn-success btn-mini" @click="arrangeBalloonsAlign('right')" title="Align right horizontally">
                  <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" transform="translate(0, 2)"><path fill="#FFFFFF" d="M800-80v-800h80v800h-80ZM320-280v-120h400v120H320ZM80-560v-120h640v120H80Z"/></svg>
                </button>
                <button class="btn btn-success btn-mini" @click="arrangeBalloonsAlign('horizontal')" title="Align centre horizontally">
                  <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" transform="translate(0, 2)"><path fill="#FFFFFF" d="M440-80v-200H240v-120h200v-160H120v-120h320v-200h80v200h320v120H520v160h200v120H520v200h-80Z"/></svg>
                </button>
                <button class="btn btn-success btn-mini" @click="arrangeBalloonsAlign('vertical')" title="Align centre vertically">
                  <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" transform="translate(0, 2)"><path fill="#FFFFFF" d="M280-120v-320H80v-80h200v-320h120v320h160v-200h120v200h200v80H680v200H560v-200H400v320H280Z"/></svg>
                </button>
              </div>
            </div>
            <div class="function-group">
              <p>Space</p>
              <div class="btn-group">
                <button class="btn btn-success btn-mini" @click="arrangeBalloonsDistributeInside(false)" title="Distribute evenly along horizontal axis between the left and right balloons">
                  <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" transform="translate(0, 4)"><path fill="#FFFFFF" d="M80-80v-800h80v800H80Zm340-200v-400h120v400H420ZM800-80v-800h80v800h-80Z"/></svg>
                </button>
                <button class="btn btn-success btn-mini" @click="arrangeBalloonsDistributeInside(true)" title="Distribute evenly along vertical axis between the top and bottom balloons">
                  <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" transform="translate(0, 4)"><path fill="#FFFFFF" d="M80-80v-80h800v80H80Zm200-340v-120h400v120H280ZM80-800v-80h800v80H80Z"/></svg>
                </button>
                <button class="btn btn-success btn-mini" @click="arrangeBalloonsAddSpace(false)" title="Space evenly along horizontal axis from the left">
                  <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" transform="translate(0, 4)"><path fill="#FFFFFF" d="M320-80 160-240l160-160 57 56-64 64h334l-63-64 56-56 160 160L640-80l-57-56 64-64H313l63 64-56 56ZM200-480v-400h80v400h-80Zm240 0v-400h80v400h-80Zm240 0v-400h80v400h-80Z"/></svg>
                </button>
                <button class="btn btn-success btn-mini" @click="arrangeBalloonsAddSpace(true)" title="Space evenly along vertical axis from the top">
                  <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" transform="translate(0, 4)"><path fill="#FFFFFF" d="M240-160 80-320l56-56 64 62v-332l-64 62-56-56 160-160 160 160-56 56-64-62v332l64-62 56 56-160 160Zm240-40v-80h400v80H480Zm0-240v-80h400v80H480Zm0-240v-80h400v80H480Z"/></svg>
                </button>
              </div>
            </div>
            <div class="function-group">
              <p>Arrange actions</p>
              <select id="arrange-select" class="success" @change="($event) => { arrangeOptionSelected($event.target.value); $event.target.value = ''; }" title="Click for selected balloon arrange options">
                <option value=''>--- Choose arrange action ---</option>
                <option value='as_pins' title="Move balloons close to their origin with vertical short arrows">Vertical short arrows</option>
                <option value='suck_in' title="Move balloons close to their origin without altering the direction">Short arrows</option>
                <option value='force_out' title="Distribute around the edges, moving to the nearest edge">Distribute to edges</option>
                <option value='repel_vert' title="Move overlapping balloons apart vertically, moving away from the centre line">Move apart vertically</option>
                <optgroup label="───────────────"></optgroup>
                <option value='high_density_merge_1' title="High density merge #1">High density merge #1</option>
              </select>
            </div>
          </div>
          <div class="options-group">
            <select @change="($event) => { startSaveImage($event.target.value); $event.target.value = ''; }" title="Click for image options">
              <option value=''>--- Choose action ---</option>
              <optgroup label="Image">
                <option value='download_drawing' title="Download the full drawing as image" >Download drawing</option>
                <option value='download_visible' title="Download the visible area as image" >Download visible area</option>
                <option value='copy_drawing' title="Copy the full drawing to the clipboard as image" >Copy drawing to clipboard</option>
                <option value='copy_visible' title="Copy the visible area to the clipboard as image" >Copy visible area to clipboard</option>
              </optgroup>
              <optgroup label="PDF">
                <option value='pdf' title="Download whole drawing as PDF" >Download drawing PDF</option>
              </optgroup>
            </select>
            <div class="btn-group">
              <button class="btn btn-primary" @click.prevent="zoomIn" :disabled="maxZoomReached" title="Click to zoom in"><i class="icon-plus icon-white"></i></button>
              <button class="btn btn-primary" @click.prevent="zoomOut" :disabled="minZoomReached" title="Click to zoom out"><i class="icon-minus icon-white"></i></button>
            </div>
          </div>
        </div>
        <div class="span2">
          <div class="options-group">
            <button class="btn" @click="revert" title="Undo all edits">Undo All Edits</button>
            <button class="btn btn-primary" v-if="!readOnly" @click="saveAndClose" title="Save changes">Save</button>
            <button type="button" class="btn btn-danger" @click="close" title="Close and discard changes">Close</button>
          </div>
        </div>
      </div>
      <div style="display:flex">
        <drawing-view class="span10"
          :background="background"
          :annotations="viewAnnotations"
          :selectedAnnotationIds="selectedAnnotationIds"
          :annotationDrawingOptions="annotationDrawingOptions"
          :relativeZoomPosition="relativeZoomPosition"
          :allowSelectMultiple="true"
          :mergeRadius="mergeRadius"
          :saveFunctionOptions="saveFunctionOptions"
          :mutateFunction="mutateFunction"

          @update:annotations_data="val => updateAnnotationsData(val)"
          @update:selectedViewAnnotationIds="val => updateSelection(val)"
          @update:mergeFunction="val => { annotationMergeFunction = val }"
          @saveImageData="val => $emit('saveImageData', { data: val, toClipboard: saveToClipboard })"
          @pdf_doc="val => $emit('pdf_doc', val)"
          @zoom="val => changeZoom(val)"
          @finished_drawing="finishedDrawing"
        />
        <div class="span2">
          <div class="right-control-panel">
            <drawing-settings
              :drawingSettings="localDrawSettings"
              :colorSchemes="colorSchemes"
              :noTypes="true"
              :noSave="true"
              @updateSettings="val => {localDrawSettings = val}"
            />
          </div>
        </div>
      </div>
    </div>
    <spinner v-show="showSpinner"/>
  </div>
</template>

<script>
  import DrawingView from './drawing_view.vue'
  import { AnnotationTypes, DrawingOptions, LayoutParams } from "../../lib/annotations/annotation"
  import DrawingSettings from "./drawing_settings"
  import spinner from '../utils/spinner_overlay.vue'

  const ZOOM_STEP_MAX = 7;
  const ZOOM_STEP_MIN = 1;
  const MAX_EDIT_CACHE_SIZE = 10;

  const AnnotationType = AnnotationTypes();

  export default {
    components: {
      DrawingView,
      DrawingSettings,
      spinner: spinner,
    },
    props: {
      background: Image,
      annotations: Array,
      readOnly: Boolean,

      drawSettings: Object,
      colorSchemes: Object,
    },
    data: function() {
      let options = new DrawingOptions();
      let colorScheme = this.drawSettings?.balloon?.color_scheme;
      let initialBalloonColor = _.get(this.colorSchemes, colorScheme);
      let initialTextScale = parseInt(this.drawSettings?.balloon?.text_scale ?? 20);
      let initialMergeRadius = this.drawSettings?.balloon?.merge_radius ?? 0;
      options.balloonOptions.drawBigPicturePointers = true;
      options.balloonOptions.fillColorOverride = initialBalloonColor?.fill_color;
      options.balloonOptions.textColorOverride = initialBalloonColor?.text_color;
      options.balloonOptions.drawBorder = !initialBalloonColor?.fill_color;
      options.balloonOptions.drawHeadlessArrows = !(this.drawSettings?.balloon?.draw_arrows ?? false);
      options.balloonOptions.textScale = initialTextScale;
      let initialLayoutParams = new LayoutParams();
      initialLayoutParams.setViewOffset(0, 0);
      return {
        zoomLevel: ZOOM_STEP_MIN,
        textScale: initialTextScale,
        localAnnotations: [],
        viewAnnotations: [],
        annotationDrawingOptions: options,
        mergeRadius: initialMergeRadius,
        annotationMergeFunction: function (annotations) {
          return annotations;
        },
        localDrawSettings: null,

        saveFunctionOptions: null,

        saveToClipboard: false,
        snackbarMessage: null,

        selectedAnnotationIds: [],
        previousSelectedAnnotationIds: [],

        editCache: [],
        editCacheIndex: 0,
        isUndoRedo: false,

        mutateFunction: null,

        context: null,
        layoutParams: initialLayoutParams,

        showSpinner: false,

        macroFunction: null,
      }
    },
    watch: {
      'localDrawSettings.balloon.text_scale': function(val) {
        this.textScale = val ?? 20;
        this.annotationDrawingOptions.balloonOptions.textScale = this.textScale;
      },
      'localDrawSettings.balloon.color_scheme': function(val) {
        let color = _.get(this.colorSchemes, val);
        this.annotationDrawingOptions.balloonOptions.fillColorOverride = color?.fill_color;
        this.annotationDrawingOptions.balloonOptions.textColorOverride = color?.text_color;
        this.annotationDrawingOptions.balloonOptions.drawBorder = !color?.fill_color;
      },
      'localDrawSettings.balloon.draw_arrows': function(val) {
        this.annotationDrawingOptions.balloonOptions.drawHeadlessArrows = !val;
      },
      'localDrawSettings.balloon.merge_radius': function(val) {
        this.mergeRadius = val ?? 0;
      },
      background: function(img) {
        this.setBackground(img);
      },
      annotations: function() {
        this.revert();
      },
      localAnnotations: function() {
        this.viewAnnotations = this.annotationMergeFunction(this.localAnnotations);
      },
      annotationMergeFunction: function () {
        this.viewAnnotations = this.annotationMergeFunction(this.localAnnotations);
      }
    },
    computed: {
      maxZoomReached: function () {
        return (this.zoomLevel == ZOOM_STEP_MAX);
      },
      minZoomReached: function () {
        return (this.zoomLevel == ZOOM_STEP_MIN);
      },
      cannotReSelect: function () {
        return this.selectedAnnotationIds === this.previousSelectedAnnotationIds || this.previousSelectedAnnotationIds.length === 0;
      },
      canUndo: function () {
        return this.editCacheIndex > 0;
      },
      canRedo: function () {
        return this.editCacheIndex < this.editCache.length;
      },
      relativeZoomPosition: function () {
        return (this.zoomLevel - ZOOM_STEP_MIN) / (ZOOM_STEP_MAX - ZOOM_STEP_MIN);
      },
    },
    mounted() {
      let canvas = document.createElement('canvas');
      this.context = canvas.getContext("2d");
      this.localAnnotations = _.cloneDeep(this.annotations ?? [])
      let drawingSettings = this.drawSettings ? _.cloneDeep(this.drawSettings) : {};
      if (!drawingSettings.balloon) drawingSettings.balloon = {};
      this.localDrawSettings = drawingSettings;
      this.setBackground(this.background);
      this.$refs.overlay.focus();
    },
    methods: {
      setBackground(img) {
        if (img) {
          this.annotationDrawingOptions.backgroundNaturalHeight = img.naturalHeight;
          this.layoutParams.setOverallSize(img.naturalWidth, img.naturalHeight);
        }
      },
      startMutate: function (method) {
        if (this.selectedAnnotationIds.length > 1000 || this.localAnnotations.length > 10000) {
          this.showSpinner = true;
          this.$forceUpdate();
        }
        setTimeout(() => {
          this.mutateFunction = method;
          this.$nextTick(() => this.mutateFunction = null);
        }, 1);
      },
      finishedDrawing: function () {
        this.showSpinner = false;
        if (this.macroFunction) {
          this.macroFunction();
        }
      },
      revert: function () {
        if (!this.isDirty() || window.confirm("Discard annotation edits?")) {
          this.localAnnotations = _.cloneDeep(this.annotations ?? [])
          this.selectedAnnotationIds = [];
          this.resetEditCache();
        }
      },
      close: function () {
        if (!this.isDirty() || window.confirm("Discard annotation edits?")) {
          this.resetEditCache();
          this.$emit("close");
        }
      },
      saveAndClose: function() {
        let changed = _.cloneDeep(_.differenceWith(this.localAnnotations, this.annotations, _.isEqual));
        this.$emit('updateSettings', this.localDrawSettings);
        this.$emit('saveSettings', this.localDrawSettings);
        this.$emit('bulk_moved_annotations', changed);
        this.resetEditCache();
        this.$emit("close");
      },
      isDirty() {
        return !_.isEqual(this.localAnnotations, this.annotations)
      },
      resetEditCache: function () {
        this.editCache = [];
        this.editCacheIndex = 0;
      },
      changeZoom: function (zoomin) {
        if (zoomin) this.zoomIn();
        else this.zoomOut();
      },
      zoomIn: function () {
        if (this.zoomLevel < ZOOM_STEP_MAX) { this.zoomLevel += 1 };
      },
      zoomOut: function () {
        if (this.zoomLevel > ZOOM_STEP_MIN) { this.zoomLevel -= 1 };
      },
      updateAnnotationsData: function(data) {
        let lastTrans = [];
        _.forEach(data, d => {
          let local = _.find(this.viewAnnotations, a => a.uuid == d.uuid);
          if (local) {
            if (!this.isUndoRedo) {
              lastTrans.push({
                uuid: d.uuid,
                before_end_x: parseFloat(local.annotation_data.end_point.x),
                before_end_y: parseFloat(local.annotation_data.end_point.y),
                after_end_x: parseFloat(d.data.end_point.x),
                after_end_y: parseFloat(d.data.end_point.y)
              })
            }
            local.annotation_data = d.data;
            let isLocallyMergedBalloon = local.annotation_type === AnnotationType.mergeBalloon && !this.annotations.find(a => a.uuid == local.uuid);
            if (isLocallyMergedBalloon) {
              // move the head of the first balloon in the list of text_balloons
              let end = d.data.end_point;
              let firstBalloon = local.text_balloons[0];
              if (firstBalloon) {
                let firstData = _.cloneDeep(firstBalloon.annotation_data);
                firstData.end_point = { x: end.x, y: end.y };
                firstBalloon.annotation_data = firstData;
              }
            }
          }
        })
        if (lastTrans.length > 0) {
          this.editCache.splice(this.editCacheIndex)
          this.editCache.push(lastTrans);
          if (this.editCacheIndex >= MAX_EDIT_CACHE_SIZE) { 
            this.editCache.splice(0, 1); // drop the first item from the cache
          } else {
            this.editCacheIndex++;
          }
        }
        this.isUndoRedo = false;
      },
      startSaveImage: function (option) {
        let isPdf = option === 'pdf'
        this.saveToClipboard = option == 'copy_drawing' || option == 'copy_visible';
        let onlyVisibleView = option == 'download_visible' || option == 'copy_visible';
        this.saveFunction = onlyVisibleView ? 'only_visible_view' : 'entire_drawing';
        this.saveFunctionOptions = { area: (onlyVisibleView ? 'only_visible_view' : 'entire_drawing'), format: (isPdf ? 'pdf' : 'image') };
        this.$nextTick(() => this.saveFunction = null);
      },

      updateSelection: function(ids) {
        this.selectedAnnotationIds = [...ids];
        this.updatePreviousSelect();
      },
      selectAll: function() {
        let ids = _.map(_.filter(this.viewAnnotations, s => this.isBalloon(s.annotation_type)), s => s.uuid);
        this.selectedAnnotationIds = ids;
        this.updatePreviousSelect();
      },
      invertSelect: function() {
        let ids = _.filter(_.map(_.filter(this.viewAnnotations, s => this.isBalloon(s.annotation_type)), s => s.uuid), id => this.selectedAnnotationIds.indexOf(id) < 0);
        this.selectedAnnotationIds = ids;
        this.updatePreviousSelect();
      },
      updatePreviousSelect: function() {
        if (this.selectedAnnotationIds.length > 0) {
          this.previousSelectedAnnotationIds = this.selectedAnnotationIds;
        }
      },
      reSelect: function() {
        if (this.selectedAnnotationIds.length === 0) {
          this.selectedAnnotationIds = this.previousSelectedAnnotationIds;
        }
      },
      isBalloon(type) {
        return type === AnnotationType.balloon || type === AnnotationType.mergeBalloon
      },


      keydown: function (evt) {
        if (evt.key === 'a' && evt.ctrlKey) {
          evt.stopPropagation();
          evt.preventDefault();
          this.selectAll();
        }
        if (evt.key === 'z' && evt.ctrlKey) {
          evt.stopPropagation();
          this.undoEdit();
        }
        if (evt.key === 'y' && evt.ctrlKey) {
          evt.stopPropagation();
          this.redoEdit();
        }
      },



      undoEdit: function() {
        this.undoRedo(true);
      },
      redoEdit: function() {
        this.undoRedo(false);
      },
      undoRedo: function(isUndo) {
        let vm = this;

        this.isUndoRedo = true;
        this.startMutate(undoRedo);

        function undoRedo(shapes) {
          let canDo = isUndo ? vm.canUndo : vm.canRedo;
          if (canDo) {
            let overallSize = vm.layoutParams.overallSize();
            let viewOffset = vm.layoutParams.viewOffset();
            vm.editCacheIndex = vm.editCacheIndex + (isUndo ? -1 : 1);
            let data = vm.editCache[vm.editCacheIndex - (isUndo ? 0 : 1)];
            let editdIds = [];
            let last = shapes.length - 1;
            for (let i = 0; i <= last; i++) {
              let shape = shapes[i];
              if (shape && vm.isBalloon(shape.type)) {
                let edit = data.find(d => d.uuid == shape.uuid);
                if (edit) {
                  editdIds.push(edit.uuid);
                  let x = isUndo ? edit.before_end_x : edit.after_end_x;
                  let y = isUndo ? edit.before_end_y : edit.after_end_y;
                  shape.positionHead(x * overallSize.width - viewOffset.x, y * overallSize.height - viewOffset.y, vm.layoutParams);
                }
              }
            }
            vm.updateSelection(editdIds);
          }
        }
      },

      arrangeOptionSelected: function(val) {
        if (val === 'as_pins') this.arrangeBalloonsAsPins();
        else if (val === 'suck_in') this.arrangeBalloonsSuckIn();
        else if (val === 'force_out') this.arrangeBalloonsForceOut();
        else if (val === 'repel_vert') this.arrangeBalloonsRepel();
        else if (val === 'high_density_merge_1') this.runMacro(this.build_highDensityMerge1_macro());
      },

      build_highDensityMerge1_macro: function () {
        return [
          (vm) => vm.buildPropertyMacroStep('localDrawSettings.balloon.color_scheme', '_blue'),
          (vm) => vm.buildPropertyMacroStep('localDrawSettings.balloon.text_scale', 6),
          (vm) => vm.buildPropertyMacroStep('localDrawSettings.balloon.merge_radius', 0.3),
          (vm) => vm.buildSelectionMacroStep(_.map(_.filter(vm.viewAnnotations, s => vm.isBalloon(s.annotation_type)), s => s.uuid)),
          (vm) => () => vm.arrangeBalloonsSuckIn(),
          (vm) => vm.buildSelectionMacroStep([]),
          (vm) => vm.buildPropertyMacroStep('localDrawSettings.balloon.text_scale', 1),
        ]
      },
      buildPropertyMacroStep: function(fieldPath, value) {
        let isSame = _.get(this, fieldPath) === value;
        if (isSame) return null; // returning null indicates there is no step to execute
        let vm = this;
        return () => {
          _.set(vm, fieldPath, value);
          let baseIndex = fieldPath.indexOf('.');
          if (baseIndex > 0) {
            // if we've mutated a deep property, then we'll replace the base object to trigger appropriate reactivity
            let basePath = fieldPath.slice(0, baseIndex);
            _.set(vm, basePath, _.cloneDeep(_.get(vm, basePath)));
          }
        };
      },
      buildSelectionMacroStep: function(ids) {
        let isSame = this.selectedAnnotationIds.length === ids.length
            && this.selectedAnnotationIds.every((v)=> ids.includes(v));
        if (isSame) return null; // returning null indicates there is no step to execute
        return () => this.selectedAnnotationIds = ids;
      },
      runMacro: function(macro) {
        let vm = this;
        iterator(macro);

        function iterator() {
          let operation;
          do {
            operation = macro.shift()(vm);
          } while (!operation && macro.length > 0);
          vm.macroFunction = macro.length > 0 
            ? () => iterator(macro)
            : null; // stop
          if (operation) {
            operation();
          }
        }
      },

      arrangeBalloonsMoveToEdge: function(edge) {
        let vm = this;
        let edgePaddingPixels = 10;

        this.startMutate(arrangeBalloonsMoveToEdge);

        function arrangeBalloonsMoveToEdge(shapes) {
          let overallSize = vm.layoutParams.overallSize();
          let viewOffset = vm.layoutParams.viewOffset();
          let last = shapes.length - 1;
          for (let i = 0; i <= last; i++) {
            let shape = shapes[i];
            if (shape && vm.isBalloon(shape.type) && vm.selectedAnnotationIds.indexOf(shape.uuid) >= 0) {
              let text_height = shape.measureTextHeight(vm.textScale, vm.background.naturalHeight, overallSize.height, true);
              let data = shape.getAnnotationData();
              let end = data.end_point;
              let offsetX = edge === 'left' ? shape.measureHeadWidth(vm.context, text_height, vm.textScale, data.full_text, true) / 2 + edgePaddingPixels
                  : edge === 'right' ? overallSize.width - shape.measureHeadWidth(vm.context, text_height, vm.textScale, data.full_text, true) / 2 - edgePaddingPixels
                  : end.x * overallSize.width;
              let offsetY = edge === 'top' ? shape.measureHeadHeight(vm.context, text_height, data.full_text, true) / 2 + edgePaddingPixels
                  : edge === 'bottom' ? overallSize.height - shape.measureHeadHeight(vm.context, text_height, data.full_text, true) / 2 - edgePaddingPixels
                  : end.y * overallSize.height;
              shape.positionHead(offsetX - viewOffset.x, offsetY - viewOffset.y, vm.layoutParams);
            }
          }
        }  
      },

      arrangeBalloonsAlign: function(where) {
        let vm = this;
        this.startMutate(arrangeBalloonsAlign);

        function arrangeBalloonsAlign(shapes) {
          let overallSize = vm.layoutParams.overallSize();
          let viewOffset = vm.layoutParams.viewOffset();
          let selectedBalloons = [];

          let lastShape = shapes.length - 1;
          for (let i = 0; i <= lastShape; i++) {
            let shape = shapes[i];
            if (shape && vm.isBalloon(shape.type) && vm.selectedAnnotationIds.indexOf(shape.uuid) >= 0) {
              let data = shape.getAnnotationData();
              let text_height = shape.measureTextHeight(vm.textScale, vm.background.naturalHeight, overallSize.height, true);
              selectedBalloons.push({
                shape: shape,
                end_x: data.end_point.x * overallSize.width,
                end_y: data.end_point.y * overallSize.height,
                head_width: shape.measureHeadWidth(vm.context, text_height, vm.textScale, data.full_text, true),
              });
            }
          }

          let balloonSort = where === 'horizontal' ? b => b.end_x
            : where === 'vertical' ? b => b.end_y
            : where === 'right' ? b => b.end_x + b.head_width / 2
            : b => b.end_x - b.head_width / 2;

          let sorted_balloons = _.sortBy(selectedBalloons, b => balloonSort(b));

          let origin_index = where === 'horizontal' ? Math.floor((selectedBalloons.length - 1) / 2)
            : where === 'vertical' ? Math.floor((selectedBalloons.length - 1) / 2)
            : where === 'right' ? Math.max(0, selectedBalloons.length - 1)
            : 0;

          let origin_balloon = sorted_balloons[origin_index];

          let origin_axis = where === 'horizontal' ? origin_balloon.end_x
            : where === 'vertical' ? origin_balloon.end_y
            : where === 'right' ? origin_balloon.end_x + origin_balloon.head_width / 2
            : origin_balloon.end_x - origin_balloon.head_width / 2;

          let lastBalloon = selectedBalloons.length - 1;
          for (let i = 0; i <= lastBalloon; i++) {
            let balloon = selectedBalloons[i];
            let xOffset = where === 'right' ? balloon.head_width / -2
              : where === 'left' ? balloon.head_width / 2
              : 0;
              let x = where === 'vertical' ? balloon.end_x : origin_axis + xOffset;
              let y = where === 'vertical' ? origin_axis : balloon.end_y;
              balloon.shape.positionHead(x - viewOffset.x, y - viewOffset.y, vm.layoutParams);
          }
        }
      },

      arrangeBalloonsAddSpace: function(isVertical) {
        let vm = this;
        let edgePaddingPixels = 10;

        this.startMutate(arrangeBalloonsAddSpace);

        function arrangeBalloonsAddSpace(shapes) {
          let overallSize = vm.layoutParams.overallSize();
          let viewOffset = vm.layoutParams.viewOffset();
          let gap = edgePaddingPixels / 2;
          let selectedBalloons = [];

          let lastShape = shapes.length - 1;
          for (let i = 0; i <= lastShape; i++) {
            let shape = shapes[i];
            if (shape && vm.isBalloon(shape.type) && vm.selectedAnnotationIds.indexOf(shape.uuid) >= 0) {
              let data = shape.getAnnotationData();
              let text_height = shape.measureTextHeight(vm.textScale, vm.background.naturalHeight, overallSize.height, true);
              gap = text_height / 2;
              selectedBalloons.push({
                shape: shape,
                end_x: data.end_point.x * overallSize.width,
                end_y: data.end_point.y * overallSize.height,
                head_width: shape.measureHeadWidth(vm.context, text_height, vm.textScale, data.full_text, true),
                head_height: shape.measureHeadHeight(vm.context, text_height, data.full_text, true),
              });
            }
          }

          // rounding (to 3 DP) is necessary to avoid odd (to the user) behaviours when spreading in both directions a collection stacked vertically
          // in a test of balloons ('Fake 0' -> 'Fake 99') Fakes 0 - 9 and 11 were 'out of order' and not on the diagonal when viewed by the user
          let balloonSort = isVertical ? b => Math.round((b.end_y - b.head_height / 2) * 1000) // multiply powers of 10 to get the DP rounding
            : b => Math.round((b.end_x - b.head_width / 2) * 1000);

          let sorted_balloons = _.sortBy(selectedBalloons, b => balloonSort(b));

          let lastEdge = 0;

          let lastBalloon = sorted_balloons.length - 1;
          for (let i = 0; i <= lastBalloon; i++) {
            let balloon = sorted_balloons[i];
            if (i === 0) {
              lastEdge = (isVertical ? balloon.end_y + balloon.head_height / 2 : balloon.end_x + balloon.head_width / 2);
            } else {
              // NOTE this will stack any overflow against the bottom or right edges
              lastEdge = isVertical ? Math.min(lastEdge + balloon.head_height + gap, overallSize.height - edgePaddingPixels) 
                : Math.min(lastEdge + balloon.head_width + gap, overallSize.width - edgePaddingPixels);
              let x = isVertical ? balloon.end_x : lastEdge - balloon.head_width / 2;
              let y = isVertical ? lastEdge - balloon.head_height / 2 : balloon.end_y;
              balloon.shape.positionHead(x - viewOffset.x, y - viewOffset.y, vm.layoutParams);
            }
          }
        }  
      },

      arrangeBalloonsDistributeInside: function(isVertical) {
        let vm = this;
        this.startMutate(arrangeBalloonsDistributeInside);

        function arrangeBalloonsDistributeInside(shapes) {
          let overallSize = vm.layoutParams.overallSize();
          let viewOffset = vm.layoutParams.viewOffset();
          let selectedBalloons = [];

          let sumDim = 0;

          let lastShape = shapes.length - 1;
          for (let i = 0; i <= lastShape; i++) {
            let shape = shapes[i];
            if (shape && vm.isBalloon(shape.type) && vm.selectedAnnotationIds.indexOf(shape.uuid) >= 0) {
              let data = shape.getAnnotationData();
              let end_x = data.end_point.x * overallSize.width;
              let end_y = data.end_point.y * overallSize.height;
              let text_height = shape.measureTextHeight(vm.textScale, vm.background.naturalHeight, overallSize.height, true);
              let head_width = shape.measureHeadWidth(vm.context, text_height, vm.textScale, data.full_text, true);
              let head_height = shape.measureHeadHeight(vm.context, text_height, data.full_text, true);
              sumDim += (isVertical ? head_height : head_width);
              selectedBalloons.push({
                shape: shape,
                end_x: end_x,
                end_y: end_y,
                head_width: head_width,
                head_height: head_height,
                left: end_x - head_width / 2,
                right: end_x + head_width / 2,
                top: end_y - head_height / 2,
                bottom: end_y + head_height / 2,
              });
            }
          }

          let balloonSort = isVertical ? b => b.end_y
            : b => b.end_x;

          let sorted_balloons = _.sortBy(selectedBalloons, b => balloonSort(b));

          let totalDim = isVertical ? sorted_balloons[sorted_balloons.length -1].bottom - sorted_balloons[0].top
            : sorted_balloons[sorted_balloons.length -1].right - sorted_balloons[0].left;
            let gap = (totalDim - sumDim) / (sorted_balloons.length - 1);

          let lastEdge = 0;

          let lastBalloon = sorted_balloons.length - 1;
          for (let i = 0; i <= lastBalloon; i++) {
            let balloon = sorted_balloons[i];
            if (i === 0) {
              lastEdge = (isVertical ? balloon.bottom : balloon.right);
            } else {
              lastEdge += (isVertical ? balloon.head_height : balloon.head_width) + gap;
              let x = isVertical ? balloon.end_x : lastEdge - balloon.head_width / 2;
              let y = isVertical ? lastEdge - balloon.head_height / 2 : balloon.end_y;
              balloon.shape.positionHead(x - viewOffset.x, y - viewOffset.y, vm.layoutParams);
            }
          }
        }
      },

      arrangeBalloonsAsPins: function() {
        let vm = this;
        this.startMutate(arrangeBalloonsAsPin);

        function arrangeBalloonsAsPin(shapes) {
          let overallSize = vm.layoutParams.overallSize();
          let viewOffset = vm.layoutParams.viewOffset();
          let last = shapes.length - 1;
          for (let i = 0; i <= last; i++) {
            let shape = shapes[i];
            if (shape && vm.isBalloon(shape.type) && vm.selectedAnnotationIds.indexOf(shape.uuid) >= 0) {
              let data = shape.getAnnotationData();
              let text_height = shape.measureTextHeight(vm.textScale, vm.background.naturalHeight, overallSize.height, true);
              let offset = shape.measureHeadHeight(vm.context, text_height, data.full_text, true) / 2 + text_height;
              let origin = shape.getAnnotationData().origin;
              let newY = origin.y * overallSize.height - offset;
              if (newY < 0) {
                newY = origin.y * overallSize.height + offset;
              }
              shape.positionHead(origin.x * overallSize.width - viewOffset.x, newY - viewOffset.y, vm.layoutParams);
            }
          }
        }  
      },
      arrangeBalloonsSuckIn: function() {
        let vm = this;
        this.startMutate(arrangeBalloonsSuckIn);

        function arrangeBalloonsSuckIn(shapes) {
          let overallSize = vm.layoutParams.overallSize();
          let last = shapes.length - 1;
          for (let i = 0; i <= last; i++) {
            let shape = shapes[i];
            if (shape && vm.isBalloon(shape.type) && vm.selectedAnnotationIds.indexOf(shape.uuid) >= 0) {
              let data = shape.getAnnotationData();
              let origin_x = data.origin.x * overallSize.width;
              let origin_y = data.origin.y * overallSize.height;
              let end_x = data.end_point.x * overallSize.width;
              let end_y = data.end_point.y * overallSize.height;

              let text_height = shape.measureTextHeight(vm.textScale, vm.background.naturalHeight, overallSize.height, true);
              let hw = shape.measureHeadWidth(vm.context, text_height, vm.textScale, data.full_text, true);
              let hh = shape.measureHeadHeight(vm.context, text_height, data.full_text, true);

              let dx_abs = Math.abs(end_x - origin_x);
              let dy_abs = Math.abs(end_y - origin_y);

              let dxr = (hw / 2) / dx_abs;
              let dyr = (hh / 2) / dy_abs;

              let offset_inside_abs = dyr < dxr ? Math.sqrt((dx_abs * dyr)**2 + (hh / 2)**2)
                : Math.sqrt((dy_abs * dxr)**2 + (hw / 2)**2);

              let offset_abs = offset_inside_abs + text_height // add height of text to offset

              let offset_ratio = offset_abs / Math.sqrt(dx_abs**2 + dy_abs**2)

              let signY = end_y > origin_y ? -1 : 1;
              let signX = end_x > origin_x ? -1 : 1;

              let new_endX = origin_x - dx_abs * offset_ratio * signX;
              let new_endY = origin_y - dy_abs * offset_ratio * signY;

              shape.positionHead(new_endX, new_endY, vm.layoutParams);
            }
          }
        }  
      },
      arrangeBalloonsForceOut: function () {
        let vm = this;
        this.startMutate(arrangeBalloonsForceOut);

        function arrangeBalloonsForceOut(shapes) {
          let viewOffset = vm.layoutParams.viewOffset();
          let imageSize = vm.layoutParams.overallSize();
          let landscape = imageSize.width >= imageSize.height;
          let imageCL = getImageCentreLine();
          let edgePaddingPixels = 10;

          let last = shapes.length - 1;
          for (let i = 0; i <= last; i++) {
            let shape = shapes[i];
            if (shape && vm.isBalloon(shape.type) && vm.selectedAnnotationIds.indexOf(shape.uuid) >= 0) {
              let data = shape.getAnnotationData();
              let origin = data.origin;
              let origin_x_abs = origin.x * imageSize.width;
              let origin_y_abs = origin.y * imageSize.height;

              let vect = getDirectionVectorToOrigin(origin_x_abs, origin_y_abs);

              let dx = origin_x_abs - vect.x;
              let dy = origin_y_abs - vect.y;

              let text_height = shape.measureTextHeight(vm.textScale, vm.background.naturalHeight, imageSize.height, true);

              let head_width_offset = shape.measureHeadWidth(vm.context, text_height, vm.textScale, data.full_text, true) / 2 + edgePaddingPixels;
              let head_height_ofset = shape.measureHeadHeight(vm.context, text_height, data.full_text, true) / 2 + edgePaddingPixels;

              let v_start_x = origin_x_abs + head_width_offset * (dx < 0 ? -1 : 1)
              let v_start_y = origin_y_abs + head_height_ofset * (dy < 0 ? -1 : 1)

              let dvx = dx < 0 ? v_start_x : imageSize.width - v_start_x;
              let dvy = dy < 0 ? v_start_y : imageSize.height - v_start_y;

              let ratio = Math.min(Math.abs(dvx / dx), Math.abs(dvy / dy));

              let new_end_x_abs = origin_x_abs + dx * ratio - viewOffset.x;
              let new_end_y_abs = origin_y_abs + dy * ratio - viewOffset.y;

              shape.positionHead(new_end_x_abs, new_end_y_abs, vm.layoutParams);
            }
          }

          function getDirectionVectorToOrigin(origin_x_abs, origin_y_abs) {
            let cl_dx = imageCL.b.x - imageCL.a.x;
            let cl_dy = imageCL.b.y - imageCL.a.y;

            let xr = origin_x_abs / imageSize.width;
            let yr = origin_y_abs / imageSize.height;

            let dx = cl_dx * xr;
            let dy = cl_dy * yr;

            return { 
              x: imageCL.a.x + dx, 
              y: imageCL.a.y + dy 
            };
          }
          function getImageCentreLine() {
            let halfShortest =  Math.round((landscape ? imageSize.height : imageSize.width) / 2);
            return { 
              a: { 
                x: halfShortest, 
                y: halfShortest, 
              }, 
              b: { 
                // this ensures b is either exactly right or below (no math rounding errors)
                x: landscape ? imageSize.width - halfShortest : halfShortest, 
                y: landscape ? halfShortest : imageSize.height - halfShortest,
              }
            };
          }
        }
      },
      arrangeBalloonsRepel: function() {
        let vm = this;
        this.startMutate(arrangeBalloonsRepel);

        function arrangeBalloonsRepel(shapes) {
          let overallSize = vm.layoutParams.overallSize();
          let viewOffset = vm.layoutParams.viewOffset();

          let balloons = [];
          let lastShape = shapes.length - 1;
          for (let i = 0; i <= lastShape; i++) {
            let shape = shapes[i];
            if (shape && vm.isBalloon(shape.type)) {
              let data = shape.getAnnotationData();
              let end_x = data.end_point.x * overallSize.width;
              let end_y = data.end_point.y * overallSize.height;
              let text_height = shape.measureTextHeight(vm.textScale, vm.background.naturalHeight, overallSize.height, true);
              let head_width = shape.measureHeadWidth(vm.context, text_height, vm.textScale, data.full_text, true);
              let head_height = shape.measureHeadHeight(vm.context, text_height, data.full_text, true);

              let balloon = {
                shape: shape,
                uuid: shape.uuid,
                origin_x: data.origin.x * overallSize.width,
                origin_y: data.origin.y * overallSize.height,
                end_x: end_x,
                end_y: end_y,
                head_width: head_width,
                head_height: head_height,
                topleft_x: end_x - head_width / 2,
                topleft_y: end_y - head_height / 2,
                bottomright_x: end_x + head_width / 2,
                bottomright_y: end_y + head_height / 2,
              };
              balloons.push(balloon);
            }
          }

          let lastBalloon = balloons.length - 1;
          let halfWidth = overallSize.width / 2;
          let halfHeight = overallSize.height / 2;

            let sections = [
              {heads: [], origins: []},
              {heads: [], origins: []},
              {heads: [], origins: []},
              {heads: [], origins: []},
            ];

          for (let i = 0; i <= lastBalloon; i++) {
            let balloon = balloons[i];
            if (balloon.origin_x <= halfWidth && balloon.origin_y <= halfHeight) sections[0].origins.push(balloon);
            if (balloon.origin_x >= halfWidth && balloon.origin_y <= halfHeight) sections[1].origins.push(balloon);
            if (balloon.origin_x <= halfWidth && balloon.origin_y > halfHeight) sections[2].origins.push(balloon);
            if (balloon.origin_x >= halfWidth && balloon.origin_y > halfHeight) sections[3].origins.push(balloon);
          }

          sections[0].heads = [];
          sections[1].heads = [];
          sections[2].heads = [];
          sections[3].heads = [];
          for (let i = 0; i <= lastBalloon; i++) {
            let balloon = balloons[i];
            if (balloon.topleft_x <= halfWidth && balloon.topleft_y <= halfHeight) sections[0].heads.push(balloon);
            if (balloon.bottomright_x >= halfWidth && balloon.topleft_y <= halfHeight) sections[1].heads.push(balloon);
            if (balloon.topleft_x <= halfWidth && balloon.bottomright_y > halfHeight) sections[2].heads.push(balloon);
            if (balloon.bottomright_x >= halfWidth && balloon.bottomright_y > halfHeight) sections[3].heads.push(balloon);
          }

          let isUpdated = arrangeTheseBalloonsRepel(sections[0], vm.selectedAnnotationIds, overallSize, true);
          isUpdated = arrangeTheseBalloonsRepel(sections[1], vm.selectedAnnotationIds, overallSize, true) || isUpdated;
          isUpdated = arrangeTheseBalloonsRepel(sections[2], vm.selectedAnnotationIds, overallSize, false) || isUpdated;
          isUpdated = arrangeTheseBalloonsRepel(sections[3], vm.selectedAnnotationIds, overallSize, false) || isUpdated;

          let last = balloons.length - 1;
          for (let i = 0; i <= last; i++) {
            let balloon = balloons[i];
            balloon.shape.positionHead(balloon.end_x - viewOffset.x, balloon.end_y - viewOffset.y, vm.layoutParams);
          }
        }

        function arrangeTheseBalloonsRepel(section, selectedIds, overallSize, up) {
          let balloons = section.heads;
          let origins = section.origins;
          let isUpdated = false;

          let margin = 2;
          
          let last = balloons.length - 1;
          for (let iCurr = 0; iCurr <= last; iCurr++) {
            let curr = balloons[iCurr];
            if (selectedIds.indexOf(curr.uuid) >= 0) {

              let moved = false;
              do {
                moved = false;
                let lastHead = balloons.length;
                for (let i = 0; i < lastHead; i++) {
                  let other = balloons[i];
                  if (other.uuid != curr.uuid) {
                  if (curr.topleft_x <= other.bottomright_x && curr.bottomright_x >= other.topleft_x
                      && curr.topleft_y <= other.bottomright_y && curr.bottomright_y >= other.topleft_y) {
                      if (up) {
                        moved = moved || curr.topleft_y > 0;
                        curr.topleft_y = Math.max(0, other.topleft_y - curr.head_height - margin);
                        curr.bottomright_y = curr.topleft_y + curr.head_height;
                        break;
                      } else {
                        moved = moved || curr.bottomright_y < overallSize.height;
                        curr.bottomright_y = Math.min(overallSize.height, other.bottomright_y + curr.head_height + margin);
                        curr.topleft_y = curr.bottomright_y - curr.head_height;
                        break;
                      }
                    }
                  }
                }
                let lastOrigin = origins.length;
                let halfHeadHeight = curr.head_height / 2;
                for (let i = 0; i < lastOrigin; i++) {
                  let other_origin = origins[i];
                  if (curr.topleft_x <= other_origin.origin_x + halfHeadHeight && curr.bottomright_x >= other_origin.origin_x - halfHeadHeight
                      && curr.topleft_y <= other_origin.origin_y + halfHeadHeight && curr.bottomright_y >= other_origin.origin_y - halfHeadHeight) {
                    if (up) {
                      moved = moved || curr.topleft_y > 0;
                      curr.topleft_y = Math.max(0, other_origin.origin_y - curr.head_height - halfHeadHeight - margin);
                      curr.bottomright_y = curr.topleft_y + curr.head_height;
                      break;
                    } else {
                      moved = moved || curr.bottomright_y < overallSize.height;
                      curr.bottomright_y = Math.min(overallSize.height, other_origin.origin_y + curr.head_height + halfHeadHeight + margin);
                      curr.topleft_y = curr.bottomright_y - curr.head_height;
                      break;
                    }
                  }
                }
                isUpdated = isUpdated || moved;

              } while (moved === true);
              curr.end_x = curr.topleft_x + curr.head_width / 2;
              curr.end_y = curr.topleft_y + curr.head_height / 2;
            }
          }
          return isUpdated;
        }
      },
    }
  }

</script>

<style scoped>
  #overlay {
    --border-radius: 6px;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 1000;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #overlay-content {
    width: 95vw;
    height: 95vh;
    padding: 8px;
    background-color: #AAA;
    border-radius: 6px;
  }

  #top-bar {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  #left-bar {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  #top-controls {
    display: flex;
    flex-wrap: wrap;
    column-gap: 12px;
    justify-content: flex-start;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 6px;
  }
  .function-group {
    border: 1px solid black; 
    border-radius: 5px;
    background-color: white;
  }
  .function-group p {
    font-weight: bold; 
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .seperator {
    border-left: 2px solid #fff;
    height: 60px;
    margin-left: 6px;
    margin-right: 6px;
  }
  .options-group {
    display: flex;
    justify-content: flex-end;
  }
  .options-group > * {
    margin-left: 6px;
    margin-right: 6px;
  }
  #arrange-select {
    /* remove an unwanted bootstrap inheritance */
    margin-bottom: 0;
    background-color: rgb(91, 183, 91); /* bootstrap green */
    color: white;
  }
  .right-control-panel {
    background-color: white; 
    padding-left: 15px; 
    padding-right: 15px; 
    padding-top: 12px; 
    padding-bottom: 12px;
    margin-right: 10px;
  }

</style>
